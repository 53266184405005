import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import "@mdi/font/css/materialdesignicons.css"

Vue.use(Vuetify)
import de from "vuetify/lib/locale/de"

export default new Vuetify({
	icons: {
		iconfont: "mdi"
	},
	lang: {
		locales: { de },
		current: "de"
	}
})
