<template>
	<v-container>
		<v-row class="text-center">
			<v-col class="mb-4" cols="12">
				<h1 class="display-2 font-weight-bold mb-3">RBBL-Statistiken</h1>

				<p class="subheading font-weight-regular">
					Folgend finden sie eine Übersicht aller Spielstatistiken, Vor- &
					Nachberichte und vieles mehr.
				</p>
			</v-col>

			<v-row justify="center">
				<v-col cols="12">
					<v-card min-width="60%">
						<v-card-title>
							<v-select
								v-model="select"
								:items="liga"
								item-text="label"
								item-value="value"
								@change="search = ''"
							>
							</v-select>
							<v-spacer></v-spacer>
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Suche"
							></v-text-field>
						</v-card-title>
						<v-data-table
							:headers="headers"
							:items="rbbl_data[select]"
							:search="search"
							class="elevation-1"
							:custom-sort="sortByDate"
							group-by="upcoming"
						>
							<template v-slot:[`item.datum`]="{ item }">
								{{ formatDate(item.datum) }}
							</template>
							<template v-slot:[`group.header`]="{ items }">
								<td colspan="11">
									<div v-if="items[0].upcoming">Kommende Spiele</div>
									<div v-if="!items[0].upcoming">Vergangene Spiele</div>
								</td>
							</template>
							<template v-slot:[`item.gast_icon`]="{ item }">
								<v-img
									v-if="item.gastshort"
									width="30"
									:src="getImgUrl(item.gastshort)"
								>
								</v-img>
							</template>

							<template v-slot:[`item.heim_icon`]="{ item }">
								<v-img
									v-if="item.heimshort"
									width="30"
									:src="getImgUrl(item.heimshort)"
								>
								</v-img>
							</template>

							<template v-slot:[`item.Statistics_actions`]="{ item }">
								<v-img
									v-if="item.livestats"
									width="30"
									@click="gotoStatisticsURL(item)"
									style="cursor: pointer"
									:src="require('../assets/livestats.png')"
								>
								</v-img>
							</template>

							<template v-slot:[`item.gastvorbericht`]="{ item }">
								<a v-if="item.gastvorbericht" :href="item.gastvorbericht"
									>Link</a
								>
							</template>
							<template v-slot:[`item.gastnachbericht`]="{ item }">
								<a v-if="item.gastnachbericht" :href="item.gastnachbericht"
									>Link</a
								>
							</template>
							<template v-slot:[`item.heimvorbericht`]="{ item }">
								<a v-if="item.heimvorbericht" :href="item.heimvorbericht"
									>Link</a
								>
							</template>
							<template v-slot:[`item.heimnachbericht`]="{ item }">
								<a v-if="item.heimnachbericht" :href="item.heimnachbericht"
									>Link</a
								>
							</template>
							<template v-slot:[`item.Livestream_actions`]="{ item }">
								<v-img
									v-if="item.livestream"
									width="50"
									@click="gotoLivestreamURL(item)"
									:src="require('../assets/live.png')"
									style="cursor: pointer"
								>
								</v-img>
							</template>
						</v-data-table>
					</v-card>
				</v-col>
			</v-row>
			<v-col class="mb-5 my-8" cols="12">
				<v-row justify="center">
					<a
						v-for="(link, i) in importantLinks"
						:key="i"
						:href="link.href"
						class="subheading mx-3"
						target="_blank"
					>
						{{ link.text }}
					</a>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
//TODO: add api from Lucas
// 1.Liga:    http://rbbl.rollstuhlbasketball.de/spielplan_laden.php/?competition=34158&modus=2
// 2.Liga:    http://rbbl.rollstuhlbasketball.de/spielplan_laden.php/?competition=34159&modus=2
import live from "../../public/data/live.json"
import teams from "../../public/data/teams.json"
import moment from "moment"
import axios from "axios"

export default {
	data() {
		return {
			name: "Statistics",
			select: "rbbl",
			liga: [
				{ label: "1. RBBL", value: "rbbl" },
				{ label: "2. RBBL", value: "rbbl2" }
			],
			search: "",
			search2: "",
			rbbl_data: { rbbl: [], rbbl2: [] },

			headers: [
				{
					text: "Datum",
					align: "start",
					value: "datum",
					sortable: false
				},
				{ text: "", value: "heim_icon", sortable: false },
				{ text: "Heim", value: "heim" },
				{
					text: "Vorbericht",
					value: "heimvorbericht",
					sortable: false
				},
				{ text: "Nachbericht", value: "heimnachbericht", sortable: false },
				{ text: "", value: "gast_icon", sortable: false },
				{ text: "Gast", value: "gast" },
				{
					text: "Vorbericht",
					value: "gastvorbericht",
					sortable: false
				},
				{ text: "Nachbericht", value: "gastnachbericht", sortable: false },
				{ text: "Livestats", value: "Statistics_actions", sortable: false },
				{ text: "Livestream", value: "Livestream_actions", sortable: false }
			],

			importantLinks: [
				{
					text: "Impressum",
					href: "https://rollstuhlbasketball.de/about-club/"
				},
				{
					text: "Datenschutz",
					href: "https://www.rollstuhlbasketball.de/datenschutz"
				}
			]
		}
	},
	methods: {
		formatDate(value) {
			return moment(value).format("DD.MM.YYYY")
		},
		sortByDate(items) {
			var arr_old = []
			var arr_upcoming = []

			items.forEach((item) => {
				if (moment(item.datum, "YYYY-MM-DD").isBefore(moment(), "day")) {
					item.upcoming = false
					arr_upcoming.push(item)
				} else {
					item.upcoming = true
					arr_old.push(item)
				}
			})
			var arr_ret = arr_old.concat(arr_upcoming)

			arr_ret.sort((a, b) => {
				moment(a.datum).diff(moment(b.datum))
			})
			return arr_ret
		},
		getLive(k) {
			k = this.replaceUmlaute(k)
			k = k.replace(/ /g, "")
			return live[k]
		},
		gotoStatisticsURL(item) {
			window.open(item.livestats)
		},
		gotoLivestreamURL(item) {
			function isValidHttpUrl(string) {
				let url

				try {
					url = new URL(string)
				} catch (_) {
					return false
				}

				return url.protocol === "http:" || url.protocol === "https:"
			}

			if (isValidHttpUrl(item.livestream)) {
				window.open(item.livestream)
			} else {
				window.open("http://" + item.livestream)
			}
		},
		replaceUmlaute(str) {
			const umlautMap = {
				"\u00dc": "UE",
				"\u00c4": "AE",
				"\u00d6": "OE",
				"\u00fc": "ue",
				"\u00e4": "ae",
				"\u00f6": "oe",
				"\u00df": "ss"
			}
			return str
				.replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
					const big = umlautMap[a.slice(0, 1)]
					return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1)
				})
				.replace(
					new RegExp("[" + Object.keys(umlautMap).join("|") + "]", "g"),
					(a) => umlautMap[a]
				)
		},
		getImgUrl(path) {
			var images = require.context("../assets/Teams/")
			return images("./" + this.replaceUmlaute(path) + ".jpg")
		},
		getShort(short) {
			for (var t in teams) {
				if (teams[t]["team"] == short) {
					return teams[t]["teamshort"]
				}
			}
			return ""
		},
		formatSpiele(spiele) {
			return spiele.map((spiel) => ({
				heim: decodeURIComponent(escape(spiel.Heim)),
				gast: decodeURIComponent(escape(spiel.Gast)),
				datum: spiel.Datum,
				livestats: `https://fibalivestats.dcd.shared.geniussports.com/u/GWBF/${spiel.SpielID}/`,
				gastvorbericht: spiel.Vorbericht_G,
				gastnachbericht: spiel.Nachbericht_G,
				heimvorbericht: spiel.Vorbericht_H,
				heimnachbericht: spiel.Nachbericht_H,
				livestream: spiel.Livestream,
				heimshort: this.getShort(spiel.Heim),
				gastshort: this.getShort(spiel.Gast)
			}))
		}
	},

	created() {
		axios
			.get(
				"http://rbbl.rollstuhlbasketball.de/spielplan_laden.php/?competition=36638&modus=2"
			)
			.then((req) => {
				const spiele = req.data["spiele"]
				this.rbbl_data.rbbl = this.formatSpiele(spiele)
			})
			.catch((e) => {
				console.log(e)
			})
		axios
			.get(
				"http://rbbl.rollstuhlbasketball.de/spielplan_laden.php/?competition=36639&modus=2"
			)
			.then((req) => {
				const spiele = req.data["spiele"]

				this.rbbl_data.rbbl2 = this.formatSpiele(spiele)
			})
			.catch((e) => {
				console.log(e)
			})
	}
}
</script>
