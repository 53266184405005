<template>
	<v-app>
		<v-app-bar app color="grey lighten-3" dark>
			<div class="d-flex align-center">
				<a href="https://rollstuhlbasketball.de/" target="_blank">
					<v-img
						alt="Vuetify Name"
						class="shrink mt-1 hidden-sm-and-down"
						contain
						min-width="80"
						:src="require('./assets/rbbl-logo-slogan-only.png')"
						width="80"
				/></a>
			</div>

			<v-spacer></v-spacer>

			<v-btn
				href="https://rollstuhlbasketball.de"
				target="_blank"
				text
				color="black"
			>
				<span class="mr-2">Rollstuhlbasketball.de</span>
				<v-icon>mdi-open-in-new</v-icon>
			</v-btn>
		</v-app-bar>

		<v-main>
			<Statistics />
		</v-main>
	</v-app>
</template>

<script>
import Statistics from "./components/Statistics"

export default {
	name: "App",
	components: {
		Statistics
	},
	created() {
		document.title = "RBBL Statistiken"
	},
	data: () => ({
		//
	})
}
</script>
